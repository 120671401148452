.form-group {
    position: relative;
    margin-bottom: 1.5rem;
}

.form-input {
    width: 100%;
    padding: 0.75rem;
    padding-top: 1.5rem;
    border: 2px solid #e2e8f0;
    border-radius: 0.375rem;
    font-size: 16px;
    transition: all 0.3s ease;
    color: black;
}

.form-input:focus {
    outline: none;
    border-color: #4fd1c5;
    box-shadow: 0 0 0 3px rgba(79, 209, 197, 0.1);
}

.form-group label {
    position: absolute;
    top: 0.5rem;
    left: 0.75rem;
    transition: all 0.3s ease;
    pointer-events: none;
    color: #718096;
    font-size: 0.875rem;
    background-color: white;
    padding: 0 0.25rem;
}

.form-input:focus+label,
.form-input:not(:placeholder-shown)+label {
    top: -0.5rem;
    left: 0.5rem;
    font-size: 0.75rem;
    color: #4fd1c5;
}

.checkbox-container {
    margin-bottom: 1rem;
}


@media (max-width: 640px) {
    .form-input {
        font-size: 14px;
        padding: 0.5rem;
        padding-top: 1.25rem;
    }

    .form-group label {
        font-size: 0.75rem;
    }

    .form-input:focus+label,
    .form-input:not(:placeholder-shown)+label {
        top: -0.25rem;
        font-size: 0.625rem;
    }


    .form-group {
        margin-bottom: 1rem;
    }

    .flex-col-reverse {
        flex-direction: column-reverse;
    }

    .space-y-reverse> :not([hidden])~ :not([hidden]) {
        --tw-space-y-reverse: 1;
    }

    .mb-2 {
        margin-bottom: 0.5rem;
    }

    .mt-6 {
        margin-top: 1.5rem;
    } 

    .checkbox-container {
        margin-bottom: 4rem; /* Increase this value if needed */
        position: relative;
        z-index: 10;
    }

    .checkbox-container + div {
        margin-top: -3rem; /* Adjust this value to fine-tune the overlap */
    }

    .checkbox-container label {
        display: flex;
        align-items: flex-start;
        background-color: white;
        padding: 0.5rem;
        border-radius: 0.25rem;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    }

    .checkbox-container input[type="checkbox"] {
        margin-top: 0.25rem;
    }

    button {
        padding: 0.5rem 1rem;
        font-size: 14px;
    }

}

/* Button styles */
bbutton {
    padding: 0.75rem 1.5rem;
    border-radius: 0.375rem;
    font-weight: 600;
    transition: all 0.3s ease;
    font-size: 16px;
}


button[type="submit"] {
    background-color: #4fd1c5;
    color: white;
}

button[type="submit"]:hover {
    background-color: #38b2ac;
}

button[type="button"] {
    background-color: #e2e8f0;
    color: #4a5568;
}

button[type="button"]:hover {
    background-color: #cbd5e0;
}

@media (max-width: 640px) {

}